import React from 'react';
import PropTypes from 'prop-types';
import {
  PictureBoxContainer,
  PictureBoxDiv,
  PictureBoxOverlay,
} from './picturebox.css';

// require('../../assets/css/picturebox.css');

const PictureBox = ({
  children,
  as = 'span',
  size,
  href,
  brand,
  thumbnail,
  quotee,
}) => {
  return (
    <PictureBoxContainer
      as={as}
      size={size}
      thumbnail={thumbnail}
      className="picture-box-container"
    >
      <PictureBoxDiv className="picture-box teal-cover slope-left">
        <PictureBoxOverlay />
        <div className="content">
          <a className="link-cover" href={href}>
            <span className="hidden">Cover Image</span>
          </a>
          <blockquote className="quote-full">{children}</blockquote>
          <p className="quotee">{quotee}</p>
          <img src={brand} alt="Brand" />
          <a href={href} className="read">
            Read case study
          </a>
        </div>
      </PictureBoxDiv>
    </PictureBoxContainer>
  );
};

PictureBox.propTypes = {
  children: PropTypes.string.isRequired,
  as: PropTypes.string,
  size: PropTypes.oneOf(['large', 'small']),
  href: PropTypes.string,
  brand: PropTypes.string,
  thumbnail: PropTypes.string,
  quotee: PropTypes.string,
};

export default PictureBox;

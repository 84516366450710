import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import PictureBox from 'components/picturebox';
import { Container, Row, Col } from 'react-grid-system';
import { Visible, Hidden, ScreenClassRender } from 'react-grid-system';

const MyFiles = ({ data }) => {
  return (
    <Layout>
      <Container>
        <Row>
          <Col col={12} sm={12}>
            <table>
              <thead>
                <tr>
                  <th>relativePath</th>
                  <th>prettySize</th>
                  <th>extension</th>
                  <th>birthTime</th>
                </tr>
              </thead>
              <tbody>
                {data.allFile.edges.map(({ node }, index) => (
                  <tr key={index}>
                    <td>{node.relativePath}</td>
                    <td>{node.prettySize}</td>
                    <td>{node.extension}</td>
                    <td>{node.birthTime}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col md={5}>
            <PictureBox
              as="div"
              size="large"
              href="https://www.tradeincool.com"
              brand="https://www.loqate.com/media/1595/ibm.png"
              thumbnail="https://www.loqate.com/media/1650/ibm-cs-photo.jpg"
              quotee="Dan Schallenkamp, Offering Manager - InfoSphere Information Server - Data Quality, IBM Corporation"
            >
              Trusted Partner to IBM, we do a lot of business together on behalf
              of our joint clients. Loqate delivers trusted technology, good
              support and I would recommend them.
            </PictureBox>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col col={12} sm={12}>
            <ScreenClassRender
              render={screenClass => (
                <p
                  style={{
                    fontSize: ['lg', 'xl'].includes(screenClass)
                      ? '2rem'
                      : '1rem',
                  }}
                >
                  Screen class: {screenClass}
                </p>
              )}
            />
            <Visible xs sm>
              <p>Paragraph visible on extra small and small.</p>
            </Visible>
            <Hidden xs sm>
              <p>Paragraph hidden on extra small and small.</p>
            </Hidden>
            <Visible md lg>
              <p>Paragraph visible on medium and large.</p>
            </Visible>
            <Hidden md lg>
              <p>Paragraph hidden on medium and large.</p>
            </Hidden>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

MyFiles.propTypes = {
  data: PropTypes.object.isRequired,
};

export default MyFiles;

export const query = graphql`
  query {
    allFile {
      edges {
        node {
          relativePath
          prettySize
          extension
          birthTime(fromNow: true)
        }
      }
    }
  }
`;

import styled from 'styled-components';

export const PictureBoxContainer = styled.div`
  background-image: url(https://www.loqate.com/media/1650/ibm-cs-photo.jpg);
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: relative;
  font-weight: ${({ size }) => () => {
    switch (size) {
      case 'large':
        return '500';
      case 'small':
        return '400';
      default:
        return '500';
    }
  }};
`;

export const PictureBoxDiv = styled.div`
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  // .picture-box.teal-cover
  color: #ffffff;
  ::after {
    content: '';
    position: absolute;
    top: 0;
    height: 110%;
    width: 180%;
    z-index: 0;
    display: block;
    transition: transform 0.5s ease;
    // }
    // .picture-box.slope-left:after {
    transform-origin: top right;
    transform: rotate(-30deg);
    right: 0;
    // }
    // .picture-box.teal-cover:after {
    background-color: #14c5bd;
  }
`;
export const PictureBoxOverlay = styled.div`
  // .picture-box.teal-cover .picture-box-overlay {
  background-color: #12ada6;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  opacity: 0.5;
`;
